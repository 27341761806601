import type JitsiConnection from 'JitsiConnection';
import type JitsiConference from 'JitsiConference';
import type JitsiLocalTrack from 'modules/RTC/JitsiLocalTrack';
import type JitsiRemoteTrack from 'modules/RTC/JitsiRemoteTrack';
import { jwtDecode } from 'jwt-decode';
import type JitsiParticipant from 'JitsiParticipant';
import { MeetMessageReceived, ToastMessageMeet, type TrackTypeV, type UserRoleMeetV } from '~/globals/meet';

type LocalTracks = Record<string, JitsiLocalTrack | null>;

type RemoteTracks = Record<
  string,
  {
    user: UserMeet;
    tracks: {
      video?: InnerTrackType[];
      audio?: InnerTrackType[];
    };
  }
>;
type SidebarType = 'member' | 'chat' | null;

const initialValueShareVideo = {
  link: '',
  moderator: false,
  muted: false,
  status: VideoShareStatus.Stop,
  time: 0,
  volume: 1
};
type NotificationsEnabledT = Record<JitsiNotificationKey, boolean>;
const getInitState = () => ({
  accessibility: {
    [TrackTypeMeet.video]: true,
    [TrackTypeMeet.audio]: true
  },
  accessibilityAdmin: {
    [TrackTypeMeet.video]: true,
    [TrackTypeMeet.audio]: true
  },
  notificationsEnabled: {
    addUserToLobby: true,
    joinedUser: true,
    leftUser: true,
    handUp: true
  } as NotificationsEnabledT,
  audioLevel: 0,
  sharingFrameRate: 5,
  handsList: [] as string[]
});

const InitLocalTracks = {
  video: null,
  desktop: null,
  audio: null
};

const useMeetStore = defineStore(
  'jitsiMeetStore',
  () => {
    let JitsiMeetJS = window?.JitsiMeetJS;
    const toast = useToast();
    const notification = useJitsiNotification();
    const { JITSI_APP_NAME } = useRuntimeConfig().public;

    const { routerReplace } = useRouterLocale();
    const roomStore = useRoomStore();
    const { user } = useAuthUser();
    const route = useRoute();
    const isGlobalLoading = ref(false);
    const isLoadConnect = ref(false);
    const isConnectedMeet = ref(false);
    const isShowSidebar = ref<SidebarType>(null);
    const isFullScreen = ref(false);
    const isAccessDevices = ref(false);
    const localVideoMirror = ref(true);
    const isModerator = ref(false);
    const conferenceTimestamp = ref('');
    const allDevices = ref<MediaDeviceInfo[]>([]);
    const isListViewMember = ref(false);
    const pendingModeration = ref(false);
    const activeTrack = ref<ActiveTrack | null>(null);
    const videoShareState = reactive<Omit<MeetVideoShare, 'from'> & { moderator: boolean }>({
      ...initialValueShareVideo
    });

    let connection: JitsiConnection | undefined;
    let room: JitsiConference | undefined;
    const state = reactive(getInitState());
    const selectDevices = reactive({
      audioOutput: '',
      audioInput: '',
      video: ''
    });
    const localTracks = reactive<LocalTracks>(jsonClone(InitLocalTracks));
    const remoteTracks = reactive<RemoteTracks>({});
    const usersLobby = reactive<Record<string, UserMeet>>({});
    const options = getJitsiOptions();

    const getMyUserId = () => room?.myUserId();
    const getJitsiMeetJS = () => window.JitsiMeetJS;

    const userToken = computed(() => route.query.token as string);
    const isShareScreen = computed(() => !!localTracks.desktop);
    const isActiveHand = computed(() => useIncludes(state.handsList, getMyUserId()));
    const isAccessibilityVideo = computed(() => state.accessibility.video && state.accessibilityAdmin.video);
    const isAccessibilityAudio = computed(() => state.accessibility.audio && state.accessibilityAdmin.audio);

    const devicesList = computed(() => {
      return useTransform(
        allDevices.value,
        (acc, device) => {
          if (device.deviceId) {
            switch (device.kind) {
              case 'audioinput': {
                acc.audioInput.push(device);
                return;
              }
              case 'videoinput': {
                acc.video.push(device);
                return;
              }
              case 'audiooutput': {
                acc.audioOutput.push(device);
              }
            }
          }
        },
        { audioInput: [], audioOutput: [], video: [] } as {
          audioInput: MediaDeviceInfo[];
          audioOutput: MediaDeviceInfo[];
          video: MediaDeviceInfo[];
        }
      );
    });

    const isActiveShareVideo = computed(() => videoShareState.status !== VideoShareStatus.Stop);

    const userInfo = computed(() => {
      return {
        displayName: getUserFullName(user.value)
      };
    });

    const userFromToken = computed(() => {
      const user = userToken.value ? jwtDecode<TokenUserMeet>(userToken.value)?.context?.user : {};
      return {
        id: user.id,
        moderator: user.affiliation === 'owner',
        name: user.name,
        avatar: user.avatar,
        email: user.email
      };
    });

    const notifyWithUser = async (
      key: JitsiNotificationKey,
      userId: string,
      props: { tData?: Record<string, any> } = {},
      disabledForMe = true
    ) => {
      const myUserId = getMyUserId();
      if (disabledForMe && !(userId && myUserId && userId !== myUserId)) {
        return;
      }
      if (isConnectedMeet.value) {
        notification.addNotification(key, userId, {
          user: usersLobby[userId] || remoteTracks[userId]?.user || {},
          ...props
        });

        if (!useHas(state.notificationsEnabled, key) || state.notificationsEnabled[key]) {
          await setAudioSinkId(selectDevices.audioOutput);
          await notification.playAudio(key);
        }
      }
    };

    const clearLocalTracks = () => {
      for (const type of Object.keys(localTracks)) {
        localTracks[type]?.dispose();
        localTracks[type] = null;
      }
    };

    const addLocalAudioTrackEvents = (track: JitsiLocalTrack) => {
      track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, (audioLevel: number) => {
        state.audioLevel = audioLevel;
      });
    };

    const toggleMuteTrack = (trackType: 'audio' | 'video') => (shouldMute?: boolean) => {
      const track = localTracks[trackType];
      if (!track) {
        return;
      }
      const mute = shouldMute !== undefined ? shouldMute : track.isMuted();
      mute ? track.unmute() : track.mute();
      state.accessibility[trackType] = mute;
      state.accessibilityAdmin[trackType] = true;
    };

    const toggleLocalVideo = toggleMuteTrack(TrackTypeMeet.video);
    const toggleLocalAudio = toggleMuteTrack(TrackTypeMeet.audio);

    const createLocalTracks = async () => {
      try {
        clearLocalTracks();
        const tracks = await JitsiMeetJS.createLocalTracks({
          devices: [TrackTypeMeet.video, TrackTypeMeet.audio],
          cameraDeviceId: selectDevices.video,
          micDeviceId: selectDevices.audioInput
        });
        tracks.forEach((track: JitsiLocalTrack) => {
          const type = track.getType() as TrackTypeV;
          localTracks[type] = track;

          if (type === TrackTypeMeet.video) {
            !state.accessibility.video && toggleLocalVideo(false);
          } else if (type === TrackTypeMeet.audio) {
            !state.accessibility.audio && toggleLocalAudio(false);
            addLocalAudioTrackEvents(track);
          }
        });
      } catch (e) {
        console.error(e);
      }
    };

    // Devices
    const setInputDevice = (device: 'audio' | 'video', key: keyof typeof selectDevices) => async (deviceId: string) => {
      try {
        selectDevices[key] = deviceId;
        const oldDevice = localTracks[device];
        if (oldDevice) {
          await oldDevice.dispose();
        }

        const opts: any = {
          devices: [device]
        };

        if (device === 'audio') {
          opts.micDeviceId = deviceId;
        }

        if (device === 'video') {
          opts.cameraDeviceId = deviceId;
        }

        const newTrack = (await JitsiMeetJS.createLocalTracks(opts))[0];

        if (!state.accessibility[device]) {
          newTrack.mute();
        }

        if (room) {
          await room.addTrack(newTrack);
        }

        localTracks[device] = newTrack;
        if (device === 'audio') {
          addLocalAudioTrackEvents(newTrack);
        }
      } catch (e) {
        console.log('changeDevice error', e);
        localTracks[device] = null;
      }
    };

    const getValidDeviceId = (devices: MediaDeviceInfo[], deviceId?: string) => {
      if (devices.some(device => device.deviceId === deviceId)) {
        return deviceId || '';
      }
      return devices[0]?.deviceId || '';
    };

    const setVideoDevice = setInputDevice('video', 'video');
    const setAudioInputDevice = setInputDevice('audio', 'audioInput');
    const setAudioOutputDevice = async (deviceId: string) => {
      selectDevices.audioOutput = getValidDeviceId(devicesList.value.audioOutput, deviceId);
      await JitsiMeetJS.mediaDevices.setAudioOutputDevice(selectDevices.audioOutput);
    };
    // ====
    const initDeviceOptions = () => {
      selectDevices.audioInput = getValidDeviceId(devicesList.value.audioInput, selectDevices.audioInput);
      selectDevices.audioOutput = getValidDeviceId(devicesList.value.audioOutput, selectDevices.audioOutput);
      selectDevices.video = getValidDeviceId(devicesList.value.video, selectDevices.video);
    };
    const onDeviceListChanged = async (devices: MediaDeviceInfo[]) => {
      allDevices.value = devices;
      initDeviceOptions();
      if (window.navigator && !isAccessDevices.value) {
        // @ts-expect-error
        const cameraGranted = (await navigator.permissions.query({ name: 'camera' })).state === 'granted';
        // @ts-expect-error
        const microGranted = (await navigator.permissions.query({ name: 'microphone' })).state === 'granted';
        if (microGranted && cameraGranted) {
          isAccessDevices.value = true;
        }
      }
    };

    const initLogs = () => {
      JitsiMeetJS.setLogLevel(options.value.logging.defaultLogLevel);
      useEach(options.value.logging, (level, loggerId) => {
        if (loggerId !== 'defaultLogLevel') {
          JitsiMeetJS.setLogLevelById(level, loggerId);
        }
      });
    };

    const onInit = async () => {
      JitsiMeetJS = window.JitsiMeetJS;
      JitsiMeetJS.init(options.value);
      initLogs();
      await createLocalTracks();
      JitsiMeetJS.mediaDevices.addEventListener(
        window.JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
        onDeviceListChanged
      );

      if (window.JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('output')) {
        window.JitsiMeetJS.mediaDevices.enumerateDevices(onDeviceListChanged);
      }
    };

    const setMirrorVideo = (val: boolean) => {
      localVideoMirror.value = val;
    };

    const setFullScreen = (val: boolean) => {
      isFullScreen.value = val;
    };

    const toggleFullScreen = (val?: boolean) => {
      setFullScreen(val === undefined ? !isFullScreen.value : val);
      if (isFullScreen.value) {
        document.body.requestFullscreen();
        return;
      }
      if (isDocumentFullScreen()) {
        document.exitFullscreen();
      }
    };

    const stopScreenShare = async () => {
      if (localTracks.desktop) {
        if (room) {
          await room.replaceTrack(localTracks.desktop, null);
        }
        localTracks.desktop.dispose();
        localTracks.desktop = null;
      }
    };

    const startScreenShare = async () => {
      try {
        const newTrack = (
          await JitsiMeetJS.createLocalTracks({
            devices: ['desktop']
          })
        )?.[0];
        if (newTrack) {
          newTrack.on(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => {
            stopScreenShare();
          });

          localTracks.desktop = newTrack;
          if (room) {
            room.addTrack(newTrack);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    const toggleScreenShare = () => {
      if (localTracks.desktop) {
        stopScreenShare();
        return;
      }
      startScreenShare();
    };

    const onConnectionRoom = async () => {
      isLoadConnect.value = true;

      connection = new JitsiMeetJS.JitsiConnection(JITSI_APP_NAME, userToken.value, options.value);
      if (!connection) {
        return;
      }

      connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, onConnectionSuccess);
      connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, onDisconnect);
      connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, onConnectionFailed);

      return connection.connect();
    };

    const setUserData = (participantId: string, newUserData: Partial<UserMeet>) => {
      const { user = {}, tracks = {} } = remoteTracks[participantId] || {};
      remoteTracks[participantId] = {
        tracks,
        user: {
          ...user,
          ...newUserData
        }
      };
    };

    const setLocalUser = () => {
      const myId = getMyUserId();
      if (userFromToken.value && myId) {
        setUserData(myId, userFromToken.value);
      }
    };

    const onConferenceJoined = () => {
      pendingModeration.value = false;
      isLoadConnect.value = false;
      isConnectedMeet.value = true;
      isModerator.value = !!room?.isModerator();
      setLocalUser();
      notification.playAudio('joinedUser');
    };

    const handleMuteChanged = (trackId: string) => (track: any) => {
      const participantId = track.getParticipantId();

      if (!remoteTracks[participantId]) {
        return;
      }
      const type = track.getType() as TrackTypeV;
      const isMuted = track.isMuted();
      if (
        participantId === getMyUserId() &&
        useIncludes([TrackTypeMeet.video, TrackTypeMeet.audio], type) &&
        isMuted &&
        // @ts-expect-error
        state.accessibility[type]
      ) {
        // @ts-expect-error
        state.accessibilityAdmin[type] = false;
        notifyWithUser(
          'muteRemote',
          participantId,
          {
            tData: {
              type
            }
          },
          false
        );
      }

      // @ts-expect-error
      remoteTracks[participantId].tracks[type] = useMap(
        // @ts-expect-error
        remoteTracks[participantId].tracks[type],
        (item: InnerTrackType) => {
          if (item.id === trackId) {
            item.muted = isMuted;
            item.stopped = item.muted && track.getVideoType() === 'desktop';
          }
          return item;
        }
      );
    };

    const onAddRemoteTrack = (track: JitsiRemoteTrack) => {
      const participant = (track.isLocal() ? room?.myUserId() : track.getParticipantId()) || '';
      if (!remoteTracks[participant]) {
        remoteTracks[participant] = {
          tracks: {},
          user: {}
        };
      }
      const type = track.getType();
      const trackId = track.getId() || '';
      const muted = track.isMuted();

      remoteTracks[participant].tracks = {
        ...remoteTracks[participant].tracks,
        [type]: [
          // @ts-expect-error
          ...(remoteTracks[participant].tracks[type] || []),
          {
            id: trackId,
            track,
            muted,
            stopped: muted && track.getVideoType() === 'desktop'
          }
        ]
      };

      track.on(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, handleMuteChanged(trackId));
    };

    const onTrackRemoved = async (track: JitsiRemoteTrack) => {
      const participant = (track.isLocal() ? room?.myUserId() : track.getParticipantId()) || '';
      if (remoteTracks[participant]?.tracks) {
        // @ts-expect-error
        remoteTracks[participant].tracks[track.getType()] = useFilter(
          // @ts-expect-error
          remoteTracks[participant].tracks[track.getType()],
          (item: InnerTrackType) => {
            return item.track.getId() !== track.getId();
          }
        );
      }
      await track.dispose();
    };

    const onUserLeft = async (participantId: string) => {
      if (remoteTracks[participantId]) {
        notifyWithUser('leftUser', participantId);

        await Promise.all(
          useMap(Object.values(remoteTracks[participantId].tracks).flat(), item => onTrackRemoved(item.track))
        );
        delete remoteTracks[participantId];
      }
    };

    const onConferenceLeft = () => {
      console.log('onLeft');
    };

    const onUserRoleChanged = (participantId: string, role: UserRoleMeetV) => {
      const moderator = role === 'moderator';
      if (participantId === room?.myUserId()) {
        if (!isModerator.value && moderator) {
          toast.info(ToastMessageMeet.setRoleModerator, {
            autoClose: 1000
          });
        }
        isModerator.value = moderator;
      }

      if (remoteTracks[participantId]) {
        setUserData(participantId, {
          moderator
        });
      }
    };

    const onCreateTime = (timestamp: string) => {
      conferenceTimestamp.value = timestamp;
    };
    const onConnectionFailed = (errorType: string) => {
      isLoadConnect.value = false;

      toast.error((MessagesError as any)[errorType] || errorType);
    };

    const onConferenceFailed = (errorType: string) => {
      isLoadConnect.value = false;
      switch (errorType) {
        case JitsiMeetJS.errors.conference.CONFERENCE_DESTROYED: {
          onLeaveRoom();
          toast.info(ToastMessageMeet.terminated);
          return;
        }
        case JitsiMeetJS.errors.conference.MEMBERS_ONLY_ERROR: {
          pendingModeration.value = true;
          room?.joinLobby(JSON.stringify(userFromToken.value), '');
          return;
        }
        case JitsiMeetJS.errors.conference.NOT_ALLOWED_ERROR: {
          routerReplace({
            name: 'dashboard-rooms-roomId-meet-auth-error',
            params: { roomId: roomStore.roomId }
          });
          return;
        }
        default: {
          pendingModeration.value = false;
          toast.error((MessagesError as any)[errorType] || errorType);
        }
      }
    };

    const onUserJoined = (participantId: string, user: JitsiParticipant) => {
      setUserData(participantId, {
        ...(user.getIdentity() as any)?.user,
        moderator: user.isModerator()
      });
      notifyWithUser('joinedUser', participantId);
    };

    const onUserJoinedLobby = (userId: string, user?: string) => {
      try {
        usersLobby[userId] = JSON.parse(user || '') as UserMeet;
      } catch (e) {
        console.error(e);
        usersLobby[userId] = {
          id: 0,
          name: user
        };
      }
      notifyWithUser('addUserToLobby', userId);
    };

    const onUserLeftLobby = (userId: string) => {
      delete usersLobby[userId];
      notification.removeNotify('joinedUser', userId);
    };

    const onLobbyApproveAccess = (userId: string) => {
      room?.lobbyApproveAccess(userId);
    };

    const onLobbyDenyAccess = (userId: string) => {
      room?.lobbyDenyAccess(userId);
    };

    /// Share start
    const sendShareVideoCommand = (options: Omit<MeetVideoShare, 'from'>) => {
      if (room) {
        const { link: value, muted, time, volume, status } = options;
        room.sendCommandOnce(CustomMeetEvent.SharedVideo, {
          value,
          attributes: {
            status,
            muted: muted === undefined ? 0 : Number(muted),
            time,
            volume: volume === undefined ? 1 : volume,
            from: room.myUserId()
          }
        });
      }
    };

    const updatedShareVideo = (options: Partial<Omit<MeetVideoShare, 'from'>>) => {
      sendShareVideoCommand({
        link: options.link || videoShareState.link,
        muted: isNil(options.muted) ? videoShareState.muted : options.muted,
        status: options.status || videoShareState.status,
        time: isNil(options.time) ? videoShareState.time : options.time,
        volume: isNil(options.volume) ? videoShareState.volume : options.volume
      });
    };

    const resetShareVideo = () => {
      for (const key in initialValueShareVideo) {
        // @ts-expect-error
        videoShareState[key] = initialValueShareVideo[key];
      }
      return true;
    };

    const resetUsersLobby = () => {
      for (const key in usersLobby) {
        delete usersLobby[key];
      }
      return true;
    };

    const stopShareVideo = () => {
      updatedShareVideo(initialValueShareVideo);
    };

    const onShareVideo = (event: MeetVideoShareEvent) => {
      videoShareState.link = event.value;
      videoShareState.muted = !!Number(event.attributes.muted);
      videoShareState.moderator = event.attributes.from === room?.myUserId();
      videoShareState.status = event.attributes.status;
      videoShareState.time = Number(event.attributes.time) || 0;
      videoShareState.volume = Number(event.attributes.volume);
    };
    /// Share end

    const toggleHand = () => {
      const myUserId = getMyUserId();
      // @ts-expect-error
      state.handsList = useXor(state.handsList, [myUserId]);
      // @ts-expect-error
      room.sendCommandOnce(CustomMeetEvent.ToggleHand, {
        value: myUserId
      });
    };

    const onToggleHand = (event: MeetToggleHand) => {
      const userId = event.value;
      if (userId === getMyUserId()) {
        return;
      }

      if (state.handsList.includes(userId)) {
        state.handsList = useFilter(state.handsList, id => id !== userId);
        notification.removeNotify('handUp', userId);
        return;
      }
      state.handsList.push(userId);
      notifyWithUser('handUp', userId);
    };

    const onKicked = () => {
      toast.info(ToastMessageMeet.adminKicked);
      //onLeaveRoom();
      routerReplace({ name: 'dashboard-rooms-roomId-chat', params: { roomId: roomStore.roomId } });
    };

    const onMessageReceived = (participant: any, event: MeetMessageReceivedEvent) => {
      switch (event?.type) {
        case MeetMessageReceived.ASK_TO_UNMUTE: {
          notifyWithUser('askToUnmute', participant.id, {}, false);
        }
      }
    };

    const onConnectionSuccess = async () => {
      if (!connection) {
        return;
      }
      room = connection.initJitsiConference(options.value.roomName, options.value);
      room.on(JitsiMeetJS.events.conference.TRACK_ADDED, onAddRemoteTrack);
      room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, onTrackRemoved);
      room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
      room.on(JitsiMeetJS.events.conference.CONFERENCE_LEFT, onConferenceLeft);
      room.on(JitsiMeetJS.events.conference.USER_ROLE_CHANGED, onUserRoleChanged);
      room.on(JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
      room.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
      room.on(JitsiMeetJS.events.conference.CONFERENCE_CREATED_TIMESTAMP, onCreateTime);
      room.on(JitsiMeetJS.events.conference.USER_VIDEO_MUTED, onCreateTime);
      room.on(JitsiMeetJS.events.conference.KICKED, onKicked);
      room.on(JitsiMeetJS.events.conference.ENDPOINT_MESSAGE_RECEIVED, onMessageReceived);
      room.on(JitsiMeetJS.events.conference.CONFERENCE_FAILED, onConferenceFailed);
      room.on(JitsiMeetJS.events.conference.LOBBY_USER_JOINED, onUserJoinedLobby);
      room.on(JitsiMeetJS.events.conference.LOBBY_USER_LEFT, onUserLeftLobby);
      room.addCommandListener(CustomMeetEvent.SharedVideo, onShareVideo);
      room.addCommandListener(CustomMeetEvent.ToggleHand, onToggleHand);

      await Promise.all(
        useMap(localTracks, track => {
          if (track) {
            return room?.addTrack(track);
          }
        })
      );
      room.setDisplayName(userInfo.value.displayName);
      room.setSenderVideoConstraint(720);
      room.setReceiverVideoConstraint(720);
      room.join('');
    };

    const onClearRemoteTracks = async () => {
      for (const participantId in remoteTracks) {
        await onUserLeft(participantId);
      }
    };

    const onClearLocalTracks = () => {
      return Promise.all(
        useMap(localTracks, async (track, key) => {
          await track?.dispose();
          delete localTracks[key];
          return true;
        })
      );
    };

    const onDisconnect = async () => {
      isConnectedMeet.value = false;
      conferenceTimestamp.value = '';
      for (const key in usersLobby) {
        delete usersLobby[key];
      }
      const pending = [onClearRemoteTracks(), onClearLocalTracks(), resetShareVideo(), resetUsersLobby()];
      if (isDocumentFullScreen()) {
        pending.push(document.exitFullscreen());
        stopScreenShare();
      }
      if (room) {
        if (room.room) {
          pending.push(room.leave(undefined));
        }
        room = undefined;
      }
      if (connection) {
        connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, onConnectionSuccess);
        connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, onDisconnect);
        connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, onConnectionFailed);
        pending.push(connection.disconnect());
        connection = undefined;
      }
      allDevices.value = [];
      const initState = getInitState();
      state.handsList = initState.handsList;
      state.audioLevel = initState.audioLevel;
      setActiveTrack(null);

      return Promise.all(pending);
    };

    const onEndMeetForAll = () => {
      room?.end();
      onLeaveRoom();
    };

    const onLeaveRoom = async () => {
      isGlobalLoading.value = true;
      await onDisconnect();
      // Reload after disconnect
      await onInit();
      // routerPush({ name: 'dashboard-rooms-roomId-chat', params: { roomId: roomStore.roomId } });
      isGlobalLoading.value = false;
    };

    const onToggleSidebar = (val: SidebarType) => {
      isShowSidebar.value = val === isShowSidebar.value ? null : val;
    };

    const onToggleListViewMember = (val?: boolean) => {
      isListViewMember.value = val === undefined ? !isListViewMember.value : val;
    };

    const setActiveTrack = (val: ActiveTrack | null) => {
      activeTrack.value = val;
    };

    const setNotificationsEnabled = (val: NotificationsEnabledT) => {
      state.notificationsEnabled = val;
    };

    const muteParticipant = (participantId: string, mediaType: TrackTypeV) => {
      if (isModerator.value) {
        room?.muteParticipant(participantId, mediaType);
      }
    };

    const kickParticipant = (participantId: string) => {
      if (isModerator.value) {
        room?.kickParticipant(participantId, 'admin kick');
      }
    };

    const grantModerator = (participantId: string) => {
      if (isModerator.value) {
        room?.grantOwner(participantId);
      }
    };

    const askToUnmute = (participantId: string) => {
      room?.sendEndpointMessage(participantId, { type: MeetMessageReceived.ASK_TO_UNMUTE });
    };

    const getRoom = () => room;
    const getConnection = () => connection;

    const reset = async () => {
      pendingModeration.value = false;
      isListViewMember.value = false;
      isConnectedMeet.value = false;
      isFullScreen.value = false;
      isAccessDevices.value = false;
      localVideoMirror.value = false;
      isGlobalLoading.value = false;
      await onDisconnect();
    };

    return {
      isFullScreen,
      isAccessDevices,
      allDevices,
      devicesList,
      selectDevices,
      localTracks,
      usersLobby,
      remoteTracks,
      localVideoMirror,
      state,
      isShareScreen,
      isConnectedMeet,
      isShowSidebar,
      isLoadConnect,
      isModerator,
      conferenceTimestamp,
      isListViewMember,
      pendingModeration,
      activeTrack,
      videoShareState,
      isActiveShareVideo,
      isGlobalLoading,
      isAccessibilityVideo,
      isAccessibilityAudio,
      isActiveHand,

      getJitsiMeetJS,
      getMyUserId,
      stopScreenShare,
      onEndMeetForAll,
      onDisconnect,
      onToggleSidebar,
      toggleScreenShare,
      toggleLocalAudio,
      toggleLocalVideo,
      setVideoDevice,
      setAudioInputDevice,
      setAudioOutputDevice,
      setFullScreen,
      setMirrorVideo,
      toggleFullScreen,
      onInit,
      onConnectionRoom,
      onLeaveRoom,
      reset,
      onToggleListViewMember,
      onLobbyApproveAccess,
      onLobbyDenyAccess,
      setActiveTrack,
      getRoom,
      getConnection,
      sendShareVideoCommand,
      updatedShareVideo,
      stopShareVideo,
      setNotificationsEnabled,
      toggleHand,
      muteParticipant,
      kickParticipant,
      grantModerator,
      askToUnmute
    };
  },
  {
    persist: {
      key: 'jitsiMeetStore',
      pick: ['selectDevices', 'state.accessibility', 'state.notificationsEnabled'],
      storage: piniaPluginPersistedstate.localStorage()
    }
  }
);

export default useMeetStore;
